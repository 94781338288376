var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-chat","title":_vm.$tc('Views.cop-t')}}),_c('v-row',{staticClass:"full-width flex-grow-0"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('bar-search',{attrs:{"search":_vm.search,"readonly":_vm.commentsLoader,"searchCallback":_vm.searchCommentByText,"label":_vm.$tc('Views.cop-b')},on:{"update:search":function($event){_vm.search=$event}}})],1)],1)],1),(!_vm.commentsLoader)?_c('v-row',{class:_vm.comments.length >= 0 && !_vm.commentsLoader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},_vm._l((_vm.comments),function(comment){return _c('v-col',{key:comment.id,staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3 custom-card",attrs:{"elevation":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('div',[_c('v-avatar',{staticClass:"mx-3",attrs:{"size":"100"}},[_c('v-img',{attrs:{"src":comment.product.photo_preview.url}})],1)],1),_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('small',[_c('b',{staticClass:"text-wrap"},[_vm._v(_vm._s(comment.product.name))])]),(comment.comments.length == 0)?_c('div',{staticClass:"d-flex align-center justify-center ml-3"},[_c('small',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$tc("Views.cop-sr")))]),_c('v-icon',{staticClass:"ml-1 warning--text"},[_vm._v("mdi-comment-alert-outline")])],1):(
                        comment.comments.length > 0 &&
                        comment.status.name !== _vm.$constants.STATUS.FINISHED
                      )?_c('div',{staticClass:"ml-3"},[_c('small',[_vm._v(_vm._s(_vm.$tc("Views.cop-1"))+" ("+_vm._s(comment.comments.length + 1)+")")])]):_c('div',{staticClass:"ml-3"},[_c('small',[_vm._v(_vm._s(_vm.$tc("Views.cop-f")))]),_c('v-icon',{staticClass:"ml-1 info--text"},[_vm._v("mdi-comment-check-outline")])],1)]),_c('div',[_c('small',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(comment.user.first_name)+" "+_vm._s(comment.user.last_name)+" ")]),_vm._v(" / "),_c('small',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(comment.user.alias)+" ")]),_vm._v(" / "),_c('small',[_vm._v(_vm._s(comment.user.phone))])])])])],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('p',{staticClass:"ma-0 pa-0 font-weight-black"},[_vm._v(" "+_vm._s(comment.text)+" ")]),_c('small',[_vm._v(_vm._s(_vm.formatedDate(comment.date)))])]),_c('div',{staticClass:"d-flex align-center"},[(comment.files.length > 0)?_c('v-btn',{attrs:{"icon":"","href":comment.files[0].url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"cta"}},[_vm._v("mdi-open-in-new")])],1):_vm._e(),_c('CommentActions',{attrs:{"comment":comment},on:{"updateComments":_vm.updateComments}})],1)])],1),_vm._l((comment.comments),function(item){return _c('v-row',{key:item.id,staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"d-flex justify-space-between align-center ma-0 pa-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-subdirectory-arrow-right")])],1),_c('div',[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(item.text))]),_c('div',[_c('small',[(
                            item.user.role.name !==
                            _vm.$constants.USER_TYPE.CLIENT
                          )?_c('b',[_vm._v(_vm._s(_vm.$tc("Views.cop-s"))+":")]):_vm._e(),_vm._v(" "+_vm._s(item.user.email))])]),_c('small',[_vm._v(_vm._s(_vm.formatedDate(item.date)))])])]),_c('div',{staticClass:"d-flex align-center"},[(item.files.length > 0)?_c('v-btn',{attrs:{"icon":"","href":item.files[0].url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"cta"}},[_vm._v("mdi-open-in-new")])],1):_vm._e(),_c('CommentActions',{attrs:{"comment":item},on:{"updateComments":_vm.updateComments}})],1)])],1)}),(comment.status.name !== _vm.$constants.STATUS.FINISHED)?_c('v-divider',{directives:[{name:"can",rawName:"v-can:create",value:([_vm.$constants.PRIVILEGES['PRODUCT COMMENT']]),expression:"[$constants.PRIVILEGES['PRODUCT COMMENT']]",arg:"create"}],staticClass:"my-3"}):_vm._e(),(comment.status.name !== _vm.$constants.STATUS.FINISHED)?_c('CommentToolbar',{directives:[{name:"can",rawName:"v-can:create",value:([_vm.$constants.PRIVILEGES['PRODUCT COMMENT']]),expression:"[$constants.PRIVILEGES['PRODUCT COMMENT']]",arg:"create"}],attrs:{"comment":comment},on:{"updateComments":_vm.updateComments}}):_vm._e()],2)],1)],1)],1)}),1):_c('Loader',{attrs:{"message":_vm.$tc('Views.cop-bc')}}),(_vm.comments.length == 0 && !_vm.commentsLoader)?_c('Empty',{attrs:{"message":_vm.$tc('Views.cop-nhcb')}}):_vm._e(),(_vm.comments.length > 0 && !_vm.commentsLoader)?_c('v-pagination',{staticClass:"my-12",attrs:{"length":_vm.totalPages,"color":_vm.getInputColor},on:{"input":_vm.changePage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }